/* filepath: /src/styles/fonts.css */
@font-face {
  font-family: 'Aeonik Regular';
  src: url('./fonts/aeonik-regular.woff2') format('woff2'), /* Include woff2 */
       url('./fonts/aeonik-regular.woff') format('woff');   /* Include woff */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik Bold';
  src: url('./fonts/aeonik-bold.woff2') format('woff2'), /* Include woff2 */
       url('./fonts/aeonik-bold.woff') format('woff');   /* Include woff */
  font-weight: normal;
  font-style: normal;
}